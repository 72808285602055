import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../components/layout";

const Contact = ({ data }) => {
  return (
    <Layout>
      <div className="hero internal py-3">
        <div className="bgimage">
        <Container>
          <Row>
            <Col md="12">
              <h1 className="pl-4 p-0 m-0">Contact us</h1>
            </Col>
          </Row>
          </Container>
        </div>
      </div>

      <Container className=" address">
        <Row className="p-4">
          <Col md="8 p-4">
            <h4 className="mb-4">
              If you are considering a building project please get in touch. You
              can email us at{" "}
              <a href="mailto:interbuilding@icloud.com">
                interbuilding@icloud.com
              </a>{" "}
              or use the form below.
            </h4>

            <form
              name="contact"
              id="contact"
              method="post"
              data-netlify="true" 
              data-netlify-honeypot="bot-field"
              action="/success"
            >
            <input type="hidden" name="form-name" value="contact" />  
              <p>
                <input type="text" name="name" placeholder="Your Name" />
              </p>
              <p>
                <input type="email" name="email" placeholder="Email Address" />
              </p>
              <p>
                <input type="text" name="phone" placeholder="Phone Number" />
              </p>
              <p>
                <textarea name="message" placeholder="How can we help?" />
              </p>
              <div data-netlify-recaptcha="true" />
              <p>
                <button type="submit">Send</button>
              </p>
            </form>
          </Col>
          <Col md="4 p-4 address">
            <h5>Interbuilding Projects</h5>
            <p>
              1 Moore Road
              <br />
              Church Crookham
              <br />
              Hampshire
              <br />
              GU52 6JB
            </p>
            <h5>
              <a href="tel:01252713547" className="mb-3">01252 713547</a><br/>
              <a href="tel:07973817334">07973 817334</a>
            </h5>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Contact;
